import React, { Component } from "react";
import { graphql, Link } from "gatsby";
import { relatedExhibit } from "~/queries/exhibitQuery"; // eslint-disable-line
import Layout from "../components/layout";
import PageWrapper from "../components/Structure/PageWrapper";
import Heading from "../components/Heading";
import SEO from "../components/seo";

class ExhibitPost extends Component {
	render() {
		const {
			data: {
				recraft: { post }
			}
		} = this.props;

		return (
			<Layout location={this.props.location}>
				<SEO
					title={post.title}
					keywords={[`dumbo`, `places`, `dumbo businesses`]}
				/>
				<PageWrapper
					mainContent={
						<div>
							<Link
								to="/exhibits"
								className="uppercase text-xs tracking-wide text-grey-dark mb-4 block"
							>
								/ &ensp; Exhibits
							</Link>
							<Heading>{post.title}</Heading>
						</div>
					}
					body={post.body && post.body.content}
				/>
			</Layout>
		);
	}
}

export default ExhibitPost;

export const pageQuery = graphql`
	query ExhibitPostByUri($uri: String!) {
		recraft {
			post: entry(uri: $uri) {
				...relatedExhibit
			}
		}
	}
`;

// <div className="container">
// 	<div className="reading w-full md:w-2/3 mx-auto my-16">
// 		<h1 className="text-3xl uppercase text-center font-din font-black mb-12">
// 			{post.title}
// 		</h1>
// 		<div className="my-8">
// 			{post.body === null ? (
// 				<h1>There's no body text</h1>
// 			) : (
// 				<div dangerouslySetInnerHTML={{ __html: post.body.content }} />
// 			)}
// 			<Link to="/articles">Back</Link>
// 		</div>
// 	</div>
// </div>
