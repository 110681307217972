/* global tw */
import React from "react";
import styled from "styled-components";

const BodyWrapper = styled.div`
	${tw``};
`;

const Body = styled.div`
	${tw`w-full md:w-3/4 mx-auto text-lg md:text-reading font-normal leading-normal md:leading-reading`};

	p {
		${tw`mb-4`};
		&:last-child,
		&:last-of-type {
			margin-bottom: 0;
		}
	}
`;

const InsetWrap = styled.div`
	${tw`bg-blue text-white p-3 w-full md:w-1/4 md:float-right md:ml-2 relative`};
`;

const InsetHeadline = styled.h2`
	${tw`text-3xl uppercase font-dincond leading-none`};
`;

const PageBody = props => {
	return props.body ? (
		<BodyWrapper>
			{props.inset ? (
				<InsetWrap>
					<InsetHeadline>VIP Deal</InsetHeadline>
					{props.inset}
				</InsetWrap>
			) : null}
			<Body dangerouslySetInnerHTML={{ __html: props.body }} />
		</BodyWrapper>
	) : null;
};

export default PageBody;

// text-base md:text-lg leading-tight md:leading-normal mb-4 font-din

// p {
// 	${tw`text-xs font-normal`};
// }
