/* global tw */
import React from "react";
import styled from "styled-components";

const Title = styled.h1`
	${tw`text-6xl uppercase leading-none font-dincond font-bold mb-8`};
`;

const Heading = props => {
	return <Title>{props.children}</Title>;
};

export default Heading;
