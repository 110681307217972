/* global tw */
import React from "react";
import { Paper } from "@material-ui/core";
import PageBody from "../PageBody/PageBody";
import styled from "styled-components";
import MicroImage from "../ImageAtom";

const ContentArea = styled.div`
	${tw`px-4 overflow-x-hidden`};
`;

const Title = styled.h1`
	${tw`text-5xl md:text-6xl lg:text-8xl uppercase font-dincond font-black md:font-bold  leading-mini mt-2 mb-4`}
`;

const PageWrapper = ({
	headImage,
	imageObject,
	mainContent,
	sidebarContent,
	body,
	inset,
	title,
	subtitle,
	bonusContent,
	...props
}) => {
	return (
		<div className="container mx-auto">
			<div className="cardboard p-2 md:p-6 md:m-6">
				<div className="flex flex-wrap -mx-4 md:mb-12">
					<ContentArea
						className={
							sidebarContent ? "w-full md:w-3/4 reading" : "w-full reading"
						}
					>
						{imageObject ? (
							<MicroImage
								imageObject={imageObject}
								title={title}
								avatarStyle={false}
								aspectRatio={"16x9"}
								placeholder={"https://placehold.it/1312x738"}
								imgixParams={"?fit=crop&h=738&w=1312"}
								imageClasses={"w-full"}
								lazyClasses={"aspect-ratio-16/9"}
							/>
						) : null}

						<Paper
							square={true}
							elevation={4}
							classes={{
								root: "p-4 md:p-6 overflow-x-hidden"
							}}
						>
							{mainContent}
							<Title>{title}</Title>
							{subtitle}
							<PageBody body={body} inset={inset} />
							{bonusContent}
						</Paper>
					</ContentArea>

					{sidebarContent ? (
						<div className="w-full md:w-1/4 px-4">{sidebarContent}</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default PageWrapper;

// ${props => (props.featured ? "0 0 100%" : "0 0 49%")};	${props => (props.featured ? "0 0 100%" : "0 0 49%")};

// <HeadImageWrapper>{headImage}</HeadImageWrapper>

// THE IMAGE IS DELIBERATELY optional... we won't include anything here if it's empty
