// /* global tw */
import React from "react";
import LazyLoad from "react-lazy-load";
// import { Link } from "gatsby";
// import styled from "styled-components";

const TheImage = ({
	title,
	imageObject,
	aspectRatio,
	placeholder,
	imageClasses,
	lazyClasses,
	imgixParams
}) => (
	<LazyLoad offsetVertical={300} className={lazyClasses}>
		<img
			alt={title}
			src={
				imageObject
					? imageObject.url +
					  (imgixParams
							? imgixParams +
							  "&auto=compress,format&fp-x=" +
							  imageObject.focalPoint.x +
							  "&fp-y=" +
							  imageObject.focalPoint.y
							: "?w=400&h=225&fit=crop&auto=compress,format&blend=0000FF&inv=true")
					: placeholder
			}
			placeholder={
				imageObject
					? imageObject.url + "?w=16&h=9&fit=crop&crop=entropy"
					: "https://placehold.it/16x9"
			}
			className={imageClasses ? imageClasses : null}
		/>
	</LazyLoad>
);

export default TheImage;

// &dpr=2

// <span>{image.caption}</span>;

// avatarStyle ? "hidden md:block w-full" : "w-full is-bw hover-color"
